import axios from "axios";
import { setupCache } from "axios-cache-adapter";

const token = window?.localStorage?.getItem("token");

const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

const axiosInstance = axios.create({
  // baseURL: "https://dashboard.hfndrone.cloudremedy.io/api/v1",
  baseURL: "https://" + window.location.hostname + "/api/v1",

  headers: {
    Authorization: `Bearer ${token}`,
  },
  cache,
});

export default axiosInstance;
