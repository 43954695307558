import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import lodash from "lodash";

import * as SettingsService from "services/settings-service";
import { addSnackbar } from "./notifications-store";
import { setIsTokenValid } from "./auth-store";

import { AppThunk } from "interfaces/app-thunk-interface";
import {
  AccountResponse,
  ConnectionAction,
  IntegrationName,
  IntegrationData,
  IntegrationType, Policy, PolicyResponse,
} from "interfaces/settings-interface";
import { DownloadFormat } from "interfaces/download-interface";
import * as SettingsComplianceService from "../../services/settings-compliance-service";
import {setLoading} from "./ui-store";

export interface SettingsStore {
  accounts: AccountResponse[];
  activeIntegration: IntegrationName;
  integrations: IntegrationData;
  policies: Policy[];
  templates: any[];
  topics: string[];
  employees: any[];
  resultSearchEmployees: any[];
}

const initialState: SettingsStore = {
  accounts: [],
  activeIntegration: "slack",
  integrations: {
    slack: { "Service Url": "", active: true, connected: false },
    "pager-duty": {
      "Api Key": "",
      active: true,
      connected: false,
    },
    "service-now": {
      "Service Url": "",
      "User Id": "",
      Password: "",
      active: true,
      connected: false,
    },
    jira: {
      "Service Url": "",
      "User Id": "",
      "Api Key": "",
      "Project Key": "",
      active: true,
      connected: false,
    },
  },
  policies: [],
  templates: [],
  topics: [],
  employees: [],
  resultSearchEmployees: [],
};

const slice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setAccounts: (
      store: SettingsStore,
      action: PayloadAction<AccountResponse[]>
    ) => {
      store.accounts = action.payload;
    },
    addAccount: (store: SettingsStore) => {
      store.accounts.push({
        name: "",
        account: "",
        role: "",
        externalid: "",
        regions: [],
        notes: "",
        active: false,
      });
    },
    updateAccount: (
      store: SettingsStore,
      action: PayloadAction<AccountResponse>
    ) => {
      const currentAccountIndex = store.accounts.findIndex(
        (account) => account.account === action.payload.account
      );

      if (currentAccountIndex > -1) {
        store.accounts[currentAccountIndex] = { ...action.payload };
      }
    },
    setConnection: (
      store: SettingsStore,
      action: PayloadAction<ConnectionAction>
    ) => {
      const currentAccountIndex = store.accounts.findIndex(
        (account) => account.account === action.payload.account
      );

      if (currentAccountIndex > -1) {
        store.accounts[currentAccountIndex].connection = action.payload.status;
      }
    },
    deleteStoreAccount: (
      store: SettingsStore,
      action: PayloadAction<string>
    ) => {
      const currentAccountIndex = store.accounts.findIndex(
        (account) => account.account === action.payload
      );

      if (currentAccountIndex > -1) {
        store.accounts.splice(currentAccountIndex, 1);
      }
    },
    setActiveIntegration: (
      store: SettingsStore,
      action: PayloadAction<IntegrationName>
    ) => {
      store.activeIntegration = action.payload;
    },
    setIntegrationData: (
      store: SettingsStore,
      action: PayloadAction<IntegrationType>
    ) => {
      const { activeIntegration } = store;
      const integrationData = action.payload;

      if (!lodash.isEmpty(integrationData)) {
        store.integrations[activeIntegration] = {
          ...store.integrations[activeIntegration],
          ...integrationData,
        };

        store.integrations[activeIntegration].connected = true;
      }
    },
    setPolicies: (
      store: SettingsStore,
      action: PayloadAction<Policy[]>
    ) => {
      store.policies = action.payload
    },
    addPolicy: (
      store: SettingsStore,
      action: PayloadAction<Policy>
    ) => {
      store.policies.push(action.payload)
    },
    deletePolicy: (
      store: SettingsStore,
      action: PayloadAction<{ policy_id: string | undefined }>
    ) => {
      store.policies = store.policies.filter(item => item.policy_id !== action.payload.policy_id)
    },
    setTemplates: (
      store: SettingsStore,
      action: PayloadAction<any[]>
    ) => {
      store.templates = action.payload
    },
    setResultSearchEmployees: (
      store: SettingsStore,
      action: PayloadAction<any[]>
    ) => {
      store.resultSearchEmployees = action.payload
    },
    resetSearchEmployees: (
      store: SettingsStore,
    ) => {
      store.resultSearchEmployees = []
    },
    setTopics: (
      store: SettingsStore,
      action: PayloadAction<string[]>
    ) => {
      store.topics = action.payload
    },
    setEmployees: (
      store: SettingsStore,
      action: PayloadAction<any[]>
    ) => {
      store.employees = action.payload
    }
  },
});

export const fetchAccounts = (): AppThunk => async (dispatch, store) => {
  await SettingsService.listAccounts()
    .then(({ data }) => dispatch(setAccounts(data.accounts)))
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }

      dispatch(addSnackbar({ text: "Can't fetch data" }));
    });
};

export const saveSettings = (account: AccountResponse): AppThunk => async (
  dispatch,
  store
) => {
  await SettingsService.saveSettings(account)
    .then(() => {
      dispatch(
        addSnackbar({
          text: `Account ${account.account} details saved.`,
          severity: "success",
        })
      );
      dispatch(updateAccount(account));
    })
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }
      let msg = ""
      if (error.response?.status === 405) {
        msg = error.response?.data.error
      }
      if (msg === undefined) {
        msg = "Can't fetch data"
      }
      dispatch(addSnackbar({
        text: msg,
        severity: "error" }));
    });
};

export const testConnection = (accountId: string): AppThunk => async (
  dispatch,
  store
) => {
  await SettingsService.testConnection(accountId)
    .then(({ data }) =>
      dispatch(setConnection({ account: accountId, status: data.status }))
    )
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }

      dispatch(addSnackbar({ text: "Can't fetch data" }));
    });
};

export const scanAccounts = (accountId: string): AppThunk => async (
  dispatch,
  store
) => {
  await SettingsService.scanAccounts(accountId)
    .then((res) => res)
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }

      dispatch(addSnackbar({ text: "Can't fetch data" }));
    });
};

export const deleteAccount = (accountId: string): AppThunk => async (
  dispatch,
  store
) => {
  await SettingsService.deleteAccount(accountId)
    .then(() => {
      dispatch(deleteStoreAccount(accountId));
      dispatch(
        addSnackbar({
          text: `Account ${accountId} deleted.`,
          severity: "error",
        })
      );
    })
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }

      dispatch(addSnackbar({ text: "Can't fetch data" }));
    });
};

export const downloadAccount = (
  accountId: string,
  format: DownloadFormat
): AppThunk => async (dispatch, store) => {
  await SettingsService.downloadAccount(accountId, format)
    .then((res) => res)
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }

      dispatch(addSnackbar({ text: "Can't fetch data" }));
    });
};

export const fetchActiveIntegration = (
  integration: IntegrationName
): AppThunk => async (dispatch, store) => {
  await SettingsService.listIntegration(integration)
    .then(({ data }) => dispatch(setIntegrationData(data)))
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }

      dispatch(addSnackbar({ text: "Can't fetch data" }));
    });
};

export const saveIntegration = (
  integration: IntegrationName,
  integrationData: IntegrationType
): AppThunk => async (dispatch, store) => {
  await SettingsService.postIntegration(integration, integrationData)
    .then(() => {
      dispatch(addSnackbar({ text: "Details saved", severity: "success" }));
      dispatch(setIntegrationData(integrationData));
    })
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }

      dispatch(addSnackbar({ text: "Can't fetch data" }));
    });
};

export const fetchPolicies = (): AppThunk => async (dispatch, store) => {
  dispatch(setLoading(true))
  await SettingsComplianceService.listPolicies()
    .then(({ data }) => {
      const transformedPolicies = data.policies.map(({ policy, topics, template }: PolicyResponse) => ({
        ...policy,
        topics,
        template
      }))
      dispatch(setPolicies(transformedPolicies))
    })
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }

      dispatch(addSnackbar({ text: "Can't fetch data" }));
    });
  dispatch(setLoading(false))
};

export const createPolicy = ({ topics, file,  ...policy }: Policy): AppThunk => async (dispatch, store) => {
  const builtPolicy = {
    policy,
    topics
  }

  const formData = new FormData()

  if (file) {
    formData.append('policy_file', file)
  }

  formData.append('payload', JSON.stringify(builtPolicy))
  await SettingsComplianceService.updatePolicy(formData)
    .then(() => {
      dispatch(addSnackbar({ text: "Policy successfully created" }));
      dispatch(fetchPolicies())
    })
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }
      if (error.response?.status === 405) {
        dispatch(addSnackbar({ text: error.response?.data.status }));
        return;
      }

      dispatch(addSnackbar({ text: "Can't fetch data" }));
    });
};

export const updatePolicy = ({ topics, file, copy, ...policy }: Policy): AppThunk => async (dispatch, store) => {
  const builtPolicy = {
    policy,
    topics
  }

  const formData = new FormData()

  if (file) {
    formData.append('policy_file', file)
  }
  formData.append('payload', JSON.stringify(builtPolicy))

  await SettingsComplianceService.updatePolicy(formData)
    .then(() => {
      dispatch(addSnackbar({ text: "Policy updated" }));
    })
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }

      dispatch(addSnackbar({ text: "Error while policy updating" }));
    });
};

export const fetchTemplates = (): AppThunk => async (dispatch, store) => {
  await SettingsComplianceService.listTemplates()
    .then(({ data }) => {
      dispatch(setTemplates(data.templates))
    })
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }

      dispatch(addSnackbar({ text: "Can't fetch data" }));
    });
};

export const searchEmployees = (name: string): AppThunk => async (dispatch, store) => {
  await SettingsComplianceService.getResultSearchEmployees(name)
    .then(({ data }) => {
      dispatch(setResultSearchEmployees(data.employees));
    })
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }

      dispatch(addSnackbar({ text: "Employees not found" }));
    });
};

export const resetResultSearchEmployees = (): AppThunk => async (dispatch, store) => {
  dispatch(resetSearchEmployees());
};

export const fetchTopics = (): AppThunk => async (dispatch, store) => {
  await SettingsComplianceService.listTopics()
    .then(({ data }) => {
      dispatch(setTopics(data.topics))
    })
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }

      dispatch(addSnackbar({ text: "Can't fetch data" }));
    });
};

export const fetchEmployees = (): AppThunk => async (dispatch, store) => {
  await SettingsComplianceService.listEmployees()
    .then(({ data }) => {
      dispatch(setEmployees(data.employees))
    })
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }

      dispatch(addSnackbar({ text: "Can't fetch data" }));
    });
};

export const downloadPolicy = (policy_name: string, file_name: string | undefined): AppThunk => async (dispatch, store) => {
  await SettingsComplianceService.downloadPolicyFile(policy_name)
    .then((response) => {
      let url = window.URL.createObjectURL(new Blob([response.data]));
      let link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file_name || 'policy.txt');
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }
      dispatch(addSnackbar({ text: "Error downloading file. Contact support" }));
    });
};

export const downloadPolicies = (): AppThunk => async (dispatch, store) => {
  await SettingsComplianceService.downloadPolicies()
    .then((response) => {
      let url = window.URL.createObjectURL(new Blob([response.data]));
      let link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", 'policies.zip');
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }
      dispatch(addSnackbar({ text: "Error downloading file. Contact support" }));
    });
};

export const downloadTemplates = (): AppThunk => async (dispatch, store) => {
  await SettingsComplianceService.downloadTemplates()
    .then((response) => {
      let url = window.URL.createObjectURL(new Blob([response.data]));
      let link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", 'templates.zip');
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }
      dispatch(addSnackbar({ text: "Error downloading file. Contact support" }));
    });
};

export const downloadTemplateFile = (policy_name: string): AppThunk => async (dispatch, store) => {
  await SettingsComplianceService.downloadTemplateFile(policy_name)
    .then((response) => {
      let url = window.URL.createObjectURL(new Blob([response.data]));
      let link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", 'template_file');
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }
      dispatch(addSnackbar({ text: "Error downloading file. Contact support" }));
    });
};

export const { reducer } = slice;
export const {
  setAccounts,
  addAccount,
  updateAccount,
  setConnection,
  deleteStoreAccount,
  setActiveIntegration,
  setIntegrationData,
  setPolicies,
  setTemplates,
  setTopics,
  setEmployees,
  addPolicy,
  deletePolicy,
  setResultSearchEmployees,
  resetSearchEmployees
} = slice.actions;
