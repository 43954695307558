import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import useStyles from './active-policies-table.styles'
import Table from "../../../../../components/table";
import {TColumn} from "../../../../../interfaces/table-interface";
import TableRow from "../../../../../components/table/table-row";
import TableColumn from "../../../../../components/table/table-column";
import TopicsDropdown from "../../topics-dropdown";
import OwnerDropdown from "../../owner-dropdown";
import Switcher from "../../../../../components/switcher";
import Button from "../../../../../components/button";
import {ReactComponent as PdfIcon} from "../../../../../icons/pdf.svg";
import {ReactComponent as UploadIcon} from "../../../../../icons/upload.svg";
import ActionsDropdown from "../../actions-dropdown";
import {TableRowProps} from "../../../../../components/table/table-row/table-row";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../../../interfaces/app-state-interface";
import {Option} from "../../../../../interfaces/common-intefaces";
import {
  deletePolicy,
  createPolicy,
  downloadPolicy,
  fetchPolicies,
  updatePolicy, fetchTemplates
} from "../../../../../redux/features/settings-store";
import DatePicker from "../../../../../components/date-picker";
import {Policy} from "../../../../../interfaces/settings-interface";
import Spinner from "../../../../../components/spinner";
import {ReactComponent as CheckMarkIcon} from "../../../../../icons/checkmark.svg";
import {ReactComponent as CloseIcon} from "../../../../../icons/close.svg";
import ReactTooltip from "react-tooltip";

const tableColumns: TColumn[] = [
  {
    flex: '1 0 calc(24% - 24px)',
    field: 'policy',
    title: 'Policy'
  },
  {
    flex: '1 0 calc(20% - 24px)',
    field: 'topics',
    title: 'Topics Covered'
  },
  {
    flex: '1 0 calc(15% - 24px)',
    field: 'reviewDate',
    title: 'Review Date',
    display: 'flex',
    justifyContent: 'center',
  },
  {
    flex: '1 0 calc(20% - 24px)',
    align: 'center',
    field: 'owner',
    title: 'Owner'
  },
  {
    flex: '1 0 calc(7% - 24px)',
    align: 'center',
    field: 'attestation',
    title: 'Ann. Empl. Attestation',
    noWrap: false
  },
  {
    flex: '1 0 calc(7% - 24px)',
    align: 'center',
    field: 'file',
    title: 'Policy File',
    noWrap: false
  },
  {
    flex: '1 0 calc(7% - 24px)',
    align: 'center',
    field: 'actions',
    title: 'Actions'
  },
]

interface TableItemProps extends TableRowProps {
  item: Policy
}

function TableItem({ item, ...otherProps }: TableItemProps) {
  const classes = useStyles()
  const dispatch = useDispatch();
  const topicsOptions = useSelector<AppState, Option[]>(
    (state) => state.settings.topics.map(value => ({ label: value, value }))
  );
  const ownersOptions = useSelector<AppState, Option[]>(
    (state) => state.settings.employees.map(({ name }) => ({
      label: name,
      value: name
    }))
  );

  const [name, setName] = useState(item.policy_name)
  const [errorName, setErrorName] = useState(false)
  const [errorOwner, setErrorOwner] = useState(false)
  const [hideTooltipPolicyName, setHideTooltipPolicyName] = useState(false)
  const [topics, setTopics] = useState<string[]>(item.topics)
  const [owner, setOwner] = useState(item.owner_name)
  const [disableCreatePolicy, setDisableCreatePolicy] = useState(true)
  const [attestation, setAttestation] = useState(item.reattestation_by_employee === 'yes')
  const [status, setStatus] = useState(item.status === 'active')
  const [reviewDate, setReviewDate] = useState(item.policy_review_date)
  const [file, setFile] = useState<File | null>(null)
  const [isLoadingSavePolicy, setIsLoadingSavePolice] = useState<boolean>(false)
  const [isLoadingSaveFile, setIsLoadingSaveFile] = useState<boolean>(false)
  const [isLoadingDownloadFile, setIsLoadingDownloadFile] = useState<boolean>(false)

  const fileInputRef = useRef<HTMLInputElement>(null)
  const nameInputRef = useRef<HTMLInputElement>(null)

  async function handleChange({ target: { name, value } }: { target: { name: string, value: any } }) {
    const updatedPolicy = { ...item }

    if (name === 'topics') {
      setTopics(value)
      updatedPolicy.topics = value
    }
    if (name === 'owner') {
      setOwner(value)
      updatedPolicy.owner_name = value
    }
    if (name === 'attestation') {
      setAttestation(value)
      updatedPolicy.reattestation_by_employee = value ? 'yes' : 'no'
    }
    if (name === 'status') {
      setStatus(value)
      updatedPolicy.status = value ? 'active' : 'inactive'
    }
    if (name === 'reviewDate') {
      setReviewDate(value)
      updatedPolicy.policy_review_date = value
    }
    if (name === 'name') {
      setErrorName(false)
      setName(value)
    }

    if (!item.copy) {
      await dispatch(updatePolicy(updatedPolicy))
      dispatch(fetchTemplates())
      dispatch(fetchPolicies())
    }
  }

  async function handleFileChange({ target: { files } }: ChangeEvent<HTMLInputElement>) {
    setIsLoadingSaveFile(true)
    if (files && !item.copy) {
      const updatedPolicy = { ...item, file: files[0] }
      await dispatch(updatePolicy(updatedPolicy))
      dispatch(fetchPolicies())
    }

    if (files && item.copy)
      setFile(files[0])
    setIsLoadingSaveFile(false)
  }

  async function handleCreatePolicy() {
    if (!owner || !name || !topics.length) return;

    const data: Policy = {
      policy_name: name,
      topics,
      owner_name: owner,
      reattestation_by_employee: attestation ? 'yes' : 'no',
      policy_review_date: reviewDate,
      status: 'active',
      file
    }

    setIsLoadingSavePolice(true)
    setDisableCreatePolicy(true)

    await dispatch(createPolicy(data))
    setIsLoadingSavePolice(false)
  }

  function handleUploadClick() {
    fileInputRef.current?.click()
  }

  async function handleDownloadFile() {
    if (file) return;
    setIsLoadingDownloadFile(true)
    await dispatch(downloadPolicy(item.policy_name, item.policy_file))
    setIsLoadingDownloadFile(false)
  }

  function handleDeletePolicy(item: Policy) {
    dispatch(deletePolicy({ policy_id: item.policy_id }))
  }

  useEffect(function initFocus() {
    if (nameInputRef.current !== null)
      nameInputRef.current.focus()
  }, [])

  useEffect(() => {
    if (!owner || !name || !topics.length) {
      setDisableCreatePolicy(true)
      return
    }
    setDisableCreatePolicy(false)
  }, [owner, name, topics])

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(function initEvents() {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (item.copy && e.code === 'Enter') {
        handleCreatePolicy()
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleCreatePolicy])
  /* eslint-enable react-hooks/exhaustive-deps */

  function displayTextWidth(text: any, font: any): HTMLCanvasElement {
    // @ts-ignore
    let canvas = displayTextWidth.canvas || (displayTextWidth.canvas = document.createElement("canvas"));
    let context = canvas.getContext("2d");
    context.font = font;
    let metrics = context.measureText(text);
    return metrics.width;
  }

  function handleCell(target: any) {
    const parentWidth = target.currentTarget.offsetWidth
    if(parentWidth < displayTextWidth(target.currentTarget.firstChild.outerText, "normal 16px 'Inter', sans-serif ")) {
      setHideTooltipPolicyName(true)
    } else {
      setHideTooltipPolicyName(false)
    }
  }

  function handleButtons() {
    if(!name) {
      setErrorName(true)
    } else {
      setErrorName(false)
    }

    if(!owner) {
      setErrorOwner(true)
    } else {
      setErrorOwner(false)
    }
    console.log('owner', !owner)
  }

  return (
    <TableRow { ...otherProps }>
      <TableColumn className={[classes.colName, errorName ? classes.errorName : ""].join(" ")}>
        {
          item.copy === true ?
            <input
              ref={nameInputRef}
              type="text"
              name="name"
              className={classes.inputText}
              placeholder="Enter a name for policy"
              required
              onChange={handleChange}
              value={name} />
            :
            <>
              <div className={classes.noWrap} onMouseEnter={(e) => handleCell(e)} data-tip data-for={'registerTip-' + item.policy_id + item.policy_name + item.node_id}><p>{ item.policy_name }</p></div>

              { hideTooltipPolicyName && <ReactTooltip id={'registerTip-' + item.policy_id + item.policy_name + item.node_id} place="top" effect="solid">
                { item.policy_name }
              </ReactTooltip> }
            </>
        }
      </TableColumn>
      <TableColumn>
        <TopicsDropdown
          name="topics"
          values={topics}
          itemId={item.policy_id}
          onChange={handleChange}
          options={topicsOptions} />
      </TableColumn>
      <TableColumn>
        <DatePicker
          name="reviewDate"
          value={reviewDate}
          onChange={handleChange} />
      </TableColumn>
      <TableColumn align={'center'}>
        <OwnerDropdown
          name="owner"
          error={errorOwner}
          value={owner}
          copy={item.copy}
          itemId={item.policy_id}
          onChange={handleChange}
          options={ownersOptions} />
      </TableColumn>
      <TableColumn>
        <Switcher
          name="attestation"
          value={attestation}
          onChange={handleChange} />
      </TableColumn>
      <TableColumn>
        {
          item?.policy_file || file  ?
            <>
              { isLoadingDownloadFile && <Spinner /> }
              { !isLoadingDownloadFile &&
                <Button onClick={handleDownloadFile} type={'icon'}>
                  <PdfIcon />
                </Button>
              }
            </>
            :
            <>
              { isLoadingSaveFile && <Spinner />}
              { !isLoadingSaveFile && <>
                <input
                  className={classes.input}
                  accept=".txt,.pdf"
                  ref={fileInputRef}
                  name="file"
                  type="file"
                  value=""
                  onChange={handleFileChange} />
                <Button onClick={handleUploadClick} className={classes.btnSquare} type={'icon'}>
                  <UploadIcon />
                </Button>
              </>}
            </>
        }
      </TableColumn>
      <TableColumn>
        { isLoadingSavePolicy && <Spinner />}
        { !isLoadingSavePolicy && <>
          {
            item.copy ?
              <div className={classes.btnsWrapper} onClick={handleButtons}>
                <Button
                  onClick={handleCreatePolicy}
                  className={classes.btnSave}
                  disabled={disableCreatePolicy}
                  type={'accent'}>
                  <CheckMarkIcon />
                </Button>

                <Button
                  onClick={() => handleDeletePolicy(item)}
                  className={classes.btnCancel}
                  type={'accent'}
                >
                  <CloseIcon />
                </Button>
              </div>
              :
              <ActionsDropdown
                status={status}
                onChange={handleChange}
                onFileChange={handleFileChange} />
          }
        </>}

      </TableColumn>
    </TableRow>
  )
}

function ActivePoliciesTable({ data, isLoading }: { data: Policy[], isLoading: boolean }) {

  function rowRenderer(item: Policy) {
    return (
      <TableItem key={item.policy_id} item={item}  />
    )
  }

  return (
    <Table
      columns={tableColumns}
      data={data}
      isLoading={isLoading}
      rowRenderer={rowRenderer} />
  )
}

export default ActivePoliciesTable
