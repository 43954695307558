export const capitalize = (str: string, lower: boolean = false) =>
  (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toUpperCase());

export const truncate = (str: string, numberOfChars: number) => {
  return str.length <= numberOfChars ? str : `${str.slice(0, numberOfChars)}...`;
};

export const getFormattedDate = (dateStr: string) => {
  const date = new Date(dateStr.replace(/-/g, "/"))
  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  };
  return new Intl.DateTimeFormat('en-US', options).format(date)
}

export const dateToString = (date: Date) => {

  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${year}-${('0' + month).slice(-2)}-${day}`
}
