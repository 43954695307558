import React, {useRef} from "react";
import useStyles from './dropdown-wrapper.styles'
import clsx from "clsx";
import {useOnClickOutside} from "../../hooks/useOnClickOutside";

interface DropdownWrapper {
  className?: string;
  opened: boolean;
  onClose: () => void;
  position?: 'left' | 'center' | 'right'
  triggerEl: React.ReactNode;
  children: React.ReactNode;
  search?: boolean,
  topicsSearch?: boolean,
}

function DropdownWrapper({ className, opened, onClose, triggerEl, children, position = 'left', search = false, topicsSearch = false }: DropdownWrapper) {
  const classes = useStyles()
  const dropdownRef = useRef(null)

  useOnClickOutside(dropdownRef, onClose)

  return (
    <div className={clsx(className, classes.root, classes[position], { [classes.opened]: opened })}>
      { triggerEl }
      <div ref={dropdownRef} className={clsx("dropdown", { [classes.notSearch]: !search, [classes.dropdown]: !search, [classes.dropDownSearch]: search, [classes.dropdownSearchTopics]: topicsSearch })}>
        { children }
      </div>
    </div>
  )
}

export default DropdownWrapper
