import React from "react";
import useStyles from './table.styles'
import clsx from "clsx";
import {TColumn} from "../../interfaces/table-interface";
import TableColumn from "./table-column";
import TableInlineSkeleton from "../skeletons/table-inline-skeleton";

interface TableProps<T> {
  className?: string,
  headerClassName?: string,
  columns: TColumn[],
  data: T[],
  rowRenderer: (item: T, index: number) => JSX.Element,
  isLoading?: boolean
}

function Table<T>({ className,  columns = [], data, rowRenderer, isLoading }: TableProps<T>) {
  const classes = useStyles();
  return (
    <div className={clsx(className, classes.root)}>
      <div className={classes.tableHeader}>
        {
          columns.map(({ title, ...props }) => (
            <TableColumn key={props.field} { ...props }>
              <p>{title}</p>
            </TableColumn>
          ))
        }
      </div>
      <div className={classes.tableBody}>
        { isLoading && <TableInlineSkeleton columns={5} rows={10} /> }
        {
          data.map((item, index) => React.cloneElement(rowRenderer(item, index), { columns }))
        }
      </div>
    </div>
  )
}

export default Table
