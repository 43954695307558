import React from "react";
import useStyles from './badge.styles'
import clsx from "clsx";

interface Badge {
  children?: React.ReactNode,
}

function Badge({ children } : Badge) {
  const classes = useStyles()

  return (
    <span className={clsx(classes.root)}>
      { children }
    </span>
  )
}

export default Badge
