import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { listToken, setIsTokenValid } from "redux/features/auth-store";
import { getCode } from "utils/auth-utils";

// const TOKEN = "eyJraWQiOiJ3TzY1b1FKRkIxZHlLSDN2Q3pnYkJJQlFkUW5LSlhYQlM0T1FZbXRTRzFzPSIsImFsZyI6IlJTMjU2In0.eyJhdF9oYXNoIjoiMXlYTl9LVmdwZ3VXbXViOGJtOVhuQSIsInN1YiI6ImI0YmRjODJmLTg4NDktNGJkNS1iYTAzLTM5MmZjMjQ4Mjk4YSIsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC51cy1lYXN0LTEuYW1hem9uYXdzLmNvbVwvdXMtZWFzdC0xXzBENXh4eEF1diIsImNvZ25pdG86dXNlcm5hbWUiOiJjcmRldiIsIm9yaWdpbl9qdGkiOiJkYmI3ZjAzNS1kNzRiLTRkYmEtOThhZi0xNjY1OGQ2NDYwNTMiLCJhdWQiOiIxNThib2NqaGpidnJsdGVwNDVuOHR0OHF2NiIsImV2ZW50X2lkIjoiZjYxNmFmMTMtZjNiOC00MzhiLTk4NTItMzViOWVjNTBkN2Q2IiwidG9rZW5fdXNlIjoiaWQiLCJhdXRoX3RpbWUiOjE2NDA2MjM3OTIsImV4cCI6MTY0MDcxMDE5MiwiaWF0IjoxNjQwNjIzNzkyLCJqdGkiOiJlYTIxZTdjNS1kZmZiLTQxNzktODFlZi0yNjk5NzZkM2MwZDMiLCJlbWFpbCI6ImF3cytjci1jcmRldi1kcm9uZUBjbG91ZHJlbWVkeS5pbyJ9.OGxGxMvkmk2V3NwxyX_mAYdvY4sSz1NWi4vKVD32masngw24abttKPPjGOlyAubi4kdIy-E-LzLS18bsCdvm91C4u-dTu9sVEgHKcHPZESIXg-rjeqGoNjg2j8G010dNIDGsQe3d1lXFyL8heeXj5mE-ZOZFx60vxSxSAyA4lfsyt8GBbm_ZQbL8vjZBUie8hxYFDd98zQW_FxMwBUEoF7jv32svuL7NphoxAjxGLOSvmloSRgQpCftDZIFcOiExspZFVdB6U854b2WO2KWUl7DYmTdQigG0EDdVLlxojXm6HM74_DTgkl_BQrmLHCEHpKBPaKZZcwj5Jf4TwySoEg"

const useAuthToken = () => {
  //redux
  const dispatch = useDispatch();

  const onPageInit = async () => {
    dispatch(setIsTokenValid(await isTokenValid()));
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    onPageInit();
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */
  // localStorage.setItem("token", TOKEN)
  const isTokenValid = async (): Promise<boolean> => {
    // localStorage.setItem('token', TOKEN)
    const token = localStorage.getItem("token");

    if (token === null) {
      const code = getCode();

      if (code) {
        await dispatch(listToken(code));
      } else {
        return false;
      }
    }

    return true;
  };
};

export default useAuthToken;
