import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    position: 'relative',
    '& > div': {
      padding: 0,
      transformOrigin: 'left top',
      transform: 'scale(0.8) translateX(-50%) !important'
    }
  },
  dropdown: {
    position: 'absolute',
    top: '100%'
  },
  picker: {
    '& div': {
      borderRadius: '0'
    }
  },
  grayText: {
    color: "rgb(183, 194, 205) !important",
  },
  btnDate: {
    width: '100%',
    textAlign: 'center',
    fontFamily: `"Inter", sans-serif`,
    fontSize: 16,
    fontWeight: 400,
    color: '#1B2E3F',
  }
}));
