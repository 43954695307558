import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {

  },
  help: {
    fontFamily: `"Inter", sans-serif`,
    fontWeight: 400,
    fontSize: 16,
    color: '#47C2A4',
    marginRight: 5,
    "& + svg path": {
      fill: "#33CCA8",
    }
  },
  text: {
    margin: '16px 0 0',
    fontFamily: `"Inter", sans-serif`,
    fontWeight: 400,
    fontSize: 18,
    lineHeight: '22px',
    color: '#61778B'
  },
  footer: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    padding: '24px 40px 32px'
  },
  subTabs: {
    order: 1,
    '@media (max-width:900px)': {
      overflow: 'scroll',
      width: "100%",
      order: 2,
      "&::-webkit-scrollbar": {
        display: "none",
      }
    },
    "& button": {
      paddingBottom: "25px",
      paddingTop: "25px",
    }
  },
  buttons: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: "12px",
    order: 2,
    '@media (max-width:900px)': {
      width: "100%",
      order: 1,
      flexDirection: "column",
      "& button": {
        marginBottom: '5px',
        marginLeft: 0,
      }
    }
  },
  btnDownload: {
    paddingLeft: 24,
    '& svg': {
      marginRight: 8
    }
  },
  btnAdd: {
    marginLeft: "8px",
    '& span': {
      marginRight: 6,
      fontSize: 21,
      fontWeight: 500
    }
  },
  spinnerWrapper: {
    marginRight: 8,
    "& div": {
      width: 22,
      height: 22,
      borderTopColor: 'white',
    }
  },
  tooltip: {
    color: theme.palette.gunSmoke.main,
  },
  header: {
    display: "flex",
    alignItems: "center",
    '@media (max-width:900px)': {
      flexDirection: "column",
    }
  }
}));
