import React, {useState} from "react";
import useStyles from './date-picker.styles'
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import clsx from "clsx";
import Button from "../button";
import {dateToString, getFormattedDate} from "../../utils/text-utils";
import DropdownWrapper from "../dropdown-wrapper";

interface DatePicker {
  className?: string;
  name: string;
  value: string;
  adopted?: boolean;
  onChange: (e: { target: { name: string, value: string } }) => void
}

function DatePicker({ className, name, value, onChange, adopted = false }: DatePicker) {
  const classes = useStyles()
  const [isOpened, setOpened] = useState(false)

  function toggleDropdown() {
    setOpened(prevState => !prevState)
  }

  function handleClose() {
    setOpened(false)
  }

  function handleDayClick(day: Date) {
    onChange({ target: { name, value: dateToString(day) } })
  }

  return (
    <DropdownWrapper
      className={[clsx(className, classes.root)].join(" ")}
      opened={isOpened}
      onClose={handleClose}
      position="center"
      triggerEl={
        <Button className={[clsx(classes.btnDate, { [classes.grayText]: adopted })]} onClick={adopted ? undefined : toggleDropdown} type={'icon'}>
          { getFormattedDate(value) }
        </Button>
      }>
      <DayPicker
        className={[classes.picker].join(" ")}
        selectedDays={new Date(value)}
        onDayClick={handleDayClick} />
    </DropdownWrapper>
  )
}

export default DatePicker
