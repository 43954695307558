import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
  },
  tab: {
    padding: '16px 24px 18px',
    position: 'relative',
    fontFamily: `"Inter", sans-serif`,
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '19px',
    color: '#1B2E3F',
    background: 'none',
    outline: 'none',
    border: 'none',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '&::after': {
      content: `""`,
      position: 'absolute',
      bottom: 0,
      left: 24,
      width: 'calc(100% - 48px)',
      height: 2,
      background: '#33CCA8',
      transition: 'transform 300ms cubic-bezier(0, 0, 0, 1)',
      transform: 'scaleX(0)'
    }
  },
  tabActive: {
    '&::after': {
      transform: 'scaleX(1)'
    },
    '& $count': {
      backgroundColor: '#EBFAF6',
      color: '#33CCA8'
    }
  },
  count: {
    padding: '3px 4px',
    backgroundColor: 'rgba(183, 194, 205, 0.2)',
    borderRadius: 4,
    marginLeft: 8,
    color: '#99A9B8',
    fontFamily: `"Inter", sans-serif`,
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '14px',
    display: 'flex',
    transition: '300ms cubic-bezier(0, 0, 0, 1)',
    transitionProperty: 'background-color, color'
  }
}));
