import {setupCache} from "axios-cache-adapter";
import axios, {AxiosPromise} from "axios";
import {PolicyResponse} from "../interfaces/settings-interface";

const token = window?.localStorage?.getItem("token");

const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

const axiosInstance = axios.create({
  // baseURL: "https://dashboard.hfndrone.cloudremedy.io/api/settings-compliance/v1",
  baseURL: "https://" + window.location.hostname + "/api/settings-compliance/v1",

  headers: {
    Authorization: `Bearer ${token}`,
  },
  cache,
});

export const listPolicies = (): AxiosPromise<{
  policies: PolicyResponse[]
}> => {
  return axiosInstance.get("/policies");
};

export const updatePolicy = (policy: FormData): AxiosPromise => {
  return axiosInstance.put("/policies", policy);
};

export const listTemplates = (): AxiosPromise<{
  templates: any[]
}> => {
  return axiosInstance.get("/templates");
};

export const listTopics = (): AxiosPromise<{
  topics: string[]
}> => {
  return axiosInstance.get("/topics");
};

export const listEmployees = (): AxiosPromise => {
  return axiosInstance.get("/employees");
};

export const getResultSearchEmployees = (name: string): AxiosPromise => {
  return axiosInstance.get("/employees", {
    params: {
      name,
    }
  });
};

export const downloadPolicyFile = (policy_name: string): AxiosPromise => {
  return axiosInstance.get("/policies/download", {
    params: {
      policy_name,
    },
    responseType: "blob",
  })
}

export const downloadPolicies = (): AxiosPromise => {
  return axiosInstance.get("/policies/download", {
    responseType: "blob",
  })
}

export const downloadTemplates = (): AxiosPromise => {
  return axiosInstance.get("/templates/download", {
    responseType: "blob",
  })
}

export const downloadTemplateFile = (policy_name: string): AxiosPromise => {
  return axiosInstance.get("/templates/download", {
    params: {
      policy_name,
    },
    responseType: "blob",
  })
}
