import React from "react";
import useStyles from './switcher.styles'
import clsx from "clsx";

interface SwitcherProps {
  className?: string;
  name: string;
  value: boolean;
  adopted?: boolean;
  onChange?: (e: { target: { name: string, value: boolean } }) => void
}

function Switcher({ className, name, value, onChange = () => {}, adopted = false}: SwitcherProps) {
  const classes = useStyles()

  function handleClick() {
    onChange({ target: { name, value: !value } })
  }

  return (
    <button
      type="button"
      onClick={adopted ? undefined : handleClick}
      className={clsx(className, classes.root, { [classes.active]: value })}>
      <span />
    </button>
  )
}

export default Switcher
