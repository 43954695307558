import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 66,
    borderBottom: '1px solid rgba(183, 194, 205, 0.3)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '0 28px 0 40px',
    '& p': {
      fontFamily: `"Inter", sans-serif`,
      //fontWeight: 400,
      fontSize: 16,
      lineHeight: '19px',
    },
    "& .dropdown-picker.dropdown": {
      border: "1px solid red"
    },
  },
}));
