import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Box, LinearProgress } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";

import { fetchAccounts } from "redux/features/settings-store";
import { fetchRegions } from "redux/features/inventory-store";
import { getHelp } from "redux/features/ui-store";

import TabsWrapper from "components/tabs-wrapper";
import ProfileSettingsAccounts from "./profile-settings-accounts";
import ProfileSettingsIntegrations from "./profile-settings-integrations";
import ProfileSettingsNewAccount from "./profile-settings-accounts/profile-settings-new-account";

import { AccountResponse } from "interfaces/settings-interface";
import { AppState } from "interfaces/app-state-interface";
import { InventoryRegionData } from "interfaces/inventory-interface";
import ProfileSettingsPolicies from "./profile-settings-policies";

const tabs = [
  {
    label: "Accounts",
    value: "accounts",
  },
  {
    label: "Integrations",
    value: "integrations",
  },
  {
    label: "Policies",
    value: "policies",
  },
];

const ProfileSettings = () => {
  // route
  const history = useHistory()
  const { search } = useLocation();

  //state
  const [currentTab, setCurrentTab] = useState("accounts");
  const [isLoading, setLoading] = useState(true);

  //redux
  const dispatch = useDispatch();
  const allAccounts = useSelector<AppState, AccountResponse[]>(
    (state) => state.settings.accounts
  );
  const regions = useSelector<AppState, InventoryRegionData[]>(
    (state) => state.inventory.regions
  );

  const accounts = currentTab === "accounts";
  const integrations = currentTab === "integrations";

  const onPageInit = async () => {
    if (accounts) {
      setLoading(true);
      await dispatch(fetchAccounts());
      await dispatch(getHelp("settings/accountGrid"));
      setLoading(false);
      if (regions.length === 0) {
        await dispatch(fetchRegions());
      }
    }
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    let path = search.split('=')[search.split('=').length - 1];
    if(path) {
      setCurrentTab(path)
    } else {
      path = "accounts"
      setCurrentTab("accounts")
    }

    onPageInit();
    history.push({
      pathname: window.location.pathname,
      search: '?tab=' + path
    })
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  if (isLoading) {
    return <LinearProgress />;
  }

  function changeTabs(currentTab: string) {
    history.push({
      pathname: window.location.pathname,
      search: '?tab=' + currentTab
    })
    setCurrentTab(currentTab)
  }

  const renderAccounts = () => {
    return (
      <Grid container spacing={10}>
        {allAccounts.map((account, index) => (
          <Grid item xs={12} key={`${account.account}-${index}`}>
            <ProfileSettingsAccounts
              accountData={account}
              allRegions={regions}
            />
          </Grid>
        ))}
        <Grid item xs={12}>
          <ProfileSettingsNewAccount />
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid>
        <TabsWrapper value={currentTab} tabs={tabs} onChange={changeTabs} />
        {currentTab !== 'policies' && <Box my={10} />}
        {accounts && renderAccounts()}
        {integrations && <ProfileSettingsIntegrations />}
        {currentTab === 'policies' && <ProfileSettingsPolicies />}
    </Grid>
  );
};

export default ProfileSettings;
