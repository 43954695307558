import React from "react";
import useStyles from './radio-button-group.styles'
import {Option} from "../../interfaces/common-intefaces";
import clsx from "clsx";

interface RadioButtonGroup {
  className?: string;
  radioClassName?: string;
  options: Option[];
  value: string;
  name: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

function RadioButtonGroup({ className, radioClassName, options, value, name, onChange }: RadioButtonGroup) {
  const classes = useStyles()

  return (
    <div className={clsx(className, {[classes.root]: options.length > 0} )}>
      {
        options.map(option => (
          <label key={option.value} className={clsx(radioClassName, classes.radio, { [classes.checked]: option.value === value })}>
            {/*<div className={clsx(classes.circle, { [classes.checked]: option.value === value })} />*/}
            <p>{ option.label }</p>
            <input
              type="radio"
              name={name}
              value={option.value}
              onChange={onChange} />
          </label>
        ))
      }
    </div>
  )
}

export default RadioButtonGroup
