import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    position: 'relative'
  },
  opened: {
    '& button': {
      pointerEvents: 'none'
    },
    '& $dropdown, & $dropDownSearch': {
      width: "100%",
      display: "block",
      opacity: 1,
      pointerEvents: 'auto'
    },
  },
  dropdown: {
    display: "none",
    position: 'absolute',
    top: 'calc(65% + 10px)',
    left: 0,
    width: '100%',
    minWidth: 'fit-content',
    background: '#fff',
    borderRadius: '10px',
    boxShadow: '0px 0px 8px rgba(90, 108, 118, 0.15)',
    opacity: 0,
    transition: '300ms cubic-bezier(0, 0, 0, 1)',
    transitionProperty: 'opacity, transform',
    zIndex: 10,
    pointerEvents: 'none',
    userSelect: 'none',
  },
  dropDownSearch: {
    display: "none",
    position: 'absolute',
    top: 'calc(65% + 5px)',
    left: 0,
    width: '84%',
    minWidth: 'fit-content',
    background: '#fff',
    borderRadius: '0 0 10px 10px',
    boxShadow: '0px 14px 10px rgba(90, 108, 118, 0.15)',
    opacity: 0,
    transition: '300ms cubic-bezier(0, 0, 0, 1)',
    transitionProperty: 'opacity, transform',
    zIndex: 10,
    pointerEvents: 'none',
    userSelect: 'none',
  },
  dropdownSearchTopics: {
    border: "initial",
  },
  notSearch: {
    padding: '15px 17px',
  },
  left: {

  },
  center: {
    '& $dropdown, & $dropDownSearch': {
      left: '50%',
      transform: 'translate(-50%, 0)'
    }
  },
  right: {
    '& $dropdown, & $dropDownSearch': {
      left: 'auto',
      right: 0
    }
  }
}));
